@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** 
 * TODO: Replace following with CSSVarsProvider once stabilized
 * see https://github.com/mui/material-ui/issues/27651
 * and https://mui.com/material-ui/experimental-api/css-variables/
 */

:root {
  --mui-palette-mode: light;
  --mui-palette-primary-main: #2196f3;
  --mui-palette-primary-light: rgb(77, 171, 245);
  --mui-palette-primary-dark: rgb(23, 105, 170);
  --mui-palette-primary-contrastText: #fff;
  --mui-palette-secondary-main: #2196f3;
  --mui-palette-secondary-light: #e3f2fd;
  --mui-palette-secondary-dark: rgb(23, 105, 170);
  --mui-palette-secondary-contrastText: #fff;
  --mui-palette-success-main: #4caf50;
  --mui-palette-success-dark: #3b873e;
  --mui-palette-success-lightBg: rgb(237, 247, 237);
  --mui-palette-success-light: rgb(111, 191, 115);
  --mui-palette-success-contrastText: rgba(0, 0, 0, 0.87);
  --mui-palette-warning-main: #ff9800;
  --mui-palette-warning-dark: #c77700;
  --mui-palette-warning-lightBg: rgb(255, 244, 229);
  --mui-palette-warning-light: rgb(255, 172, 51);
  --mui-palette-warning-contrastText: rgba(0, 0, 0, 0.87);
  --mui-palette-grey-25: #fdfdfd;
  --mui-palette-grey-50: #fafafa;
  --mui-palette-grey-100: #f5f5f5;
  --mui-palette-grey-200: #eeeeee;
  --mui-palette-grey-300: #e0e0e0;
  --mui-palette-grey-400: #bdbdbd;
  --mui-palette-grey-500: #9e9e9e;
  --mui-palette-grey-600: #757575;
  --mui-palette-grey-700: #616161;
  --mui-palette-grey-800: #424242;
  --mui-palette-grey-900: #212121;
  --mui-palette-grey-A100: #f5f5f5;
  --mui-palette-grey-A200: #eeeeee;
  --mui-palette-grey-A400: #bdbdbd;
  --mui-palette-grey-A700: #616161;
  --mui-palette-common-black: #000;
  --mui-palette-common-white: #fff;
  --mui-palette-error-main: #d32f2f;
  --mui-palette-error-light: #ef5350;
  --mui-palette-error-dark: #c62828;
  --mui-palette-error-contrastText: #fff;
  --mui-palette-info-main: #0288d1;
  --mui-palette-info-light: #03a9f4;
  --mui-palette-info-dark: #01579b;
  --mui-palette-info-contrastText: #fff;
  --mui-palette-contrastThreshold: 3px;
  --mui-palette-tonalOffset: 0.2px;
  --mui-palette-text-primary: rgba(0, 0, 0, 0.87);
  --mui-palette-text-secondary: rgba(0, 0, 0, 0.6);
  --mui-palette-text-disabled: rgba(0, 0, 0, 0.38);
  --mui-palette-text-primaryChannel: 0 0 0;
  --mui-palette-text-secondaryChannel: 0 0 0;
  --mui-palette-divider: rgba(0, 0, 0, 0.12);
  --mui-palette-background-paper: #fff;
  --mui-palette-background-default: #fff;
  --mui-palette-action-active: rgba(0, 0, 0, 0.54);
  --mui-palette-action-hover: rgba(0, 0, 0, 0.04);
  --mui-palette-action-hoverOpacity: 0.04;
  --mui-palette-action-selected: rgba(0, 0, 0, 0.08);
  --mui-palette-action-selectedOpacity: 0.08;
  --mui-palette-action-disabled: rgba(0, 0, 0, 0.26);
  --mui-palette-action-disabledBackground: rgba(0, 0, 0, 0.12);
  --mui-palette-action-disabledOpacity: 0.38;
  --mui-palette-action-focus: rgba(0, 0, 0, 0.12);
  --mui-palette-action-focusOpacity: 0.12;
  --mui-palette-action-activatedOpacity: 0.12;
  --mui-palette-action-activeChannel: 0 0 0;
  --mui-palette-action-selectedChannel: 0 0 0;
  --mui-shape-borderRadius: 4px;
  --mui-zIndex-mobileStepper: 1000;
  --mui-zIndex-fab: 1050;
  --mui-zIndex-speedDial: 1050;
  --mui-zIndex-appBar: 1100;
  --mui-zIndex-drawer: 1200;
  --mui-zIndex-modal: 1300;
  --mui-zIndex-snackbar: 1400;
  --mui-zIndex-tooltip: 1500;
}

a {
  text-decoration: none !important;
}
